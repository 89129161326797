:root{
    --global-text-color: #4b5563;
    --formio-border-color: #e5e7eb;
    --cf-primary-color:#45489e;
    --cf-primary-light-color:#585cd9;
    --cf-primary-card-hover-color:#7074e9;
    --cf-primary-dark-color:#3034a4;
    --cf-secondary-color:#ffffff;
    --cf-disabled-color:#a9acfc;
    --heading-text-color:#101828;
    --disabled-side-bar-text-color:#878b93;
    --total-certificates-color:#667085;
    --cfsearch-container-bg-color:#F5F8FF;
    --card-border-color:#EAECF0;
    --card-tag-color:#F2F4F7;
    --card-tag-hover-color:#6C788F;
    --card-text-color:#344054;
    --view-all-text-color:#667085;
    --card-hover-color:#F5F8FF;
    --sign-Up-text-color:#515E75;
    --button-color-primary:#3538CD;
    --button-color-primary-hover:#3538cddc;
    --sign-in-sign-up-heading-text-color:#101828;
    --sign-in-sign-up-intro-message-text-color:#667085;
    --sign-in-sign-up-input-border-color:#D0D5DD;
    --sign-in-sign-up-input-placeholder-text-color:#667085;
    --sign-in-sign-up-input-label-text-color:#344054;
    --cf-button-color:#3538CD;
    --forgot-password:#444CE7;
    --dont-have-account-sign-up-text:#3538CD;
    --sign-in-sign-up-or-text:#98A2B3;
    --dont-have-account-text:#667085;
    --sign-up-bottom-section-color:#F2F4F7;
    --sign-up-are-you-an-administrator-text-color:#667085;
    --sign-up-to-your-organization-account-text-color:#667085;
    --category-img-bg-color:#F0F0F0;
    --category-card-border-color:#8098F9;
    --category-card-shadow-color:#B1B4E5;
    --category-card-heading-text-color:#4F5768;
    --text-input-border-color:#A4BCFD;
    --breadcrumb-disabled-text:#6C788F;
    --breadcrumb-active-text:#101828;
    --breadcrumb-bg-color:#F9FAFB;
    --certificate-name-input-border-color:#c8ddff;
    --issued-screen-tab-button-selected-color:#E0EAFF;
    --issued-screen-tab-button-selected-text-color:#3538CD;
    --issued-screen-tab-button-unselected-text-color:#667085;
    --issued-screen-search-border:#6C788F;
    --issued-screen-search-text:#6C788F;
    --issued-credential-template-card-name-text:#101828;
    --issued-credential-template-card-date-receivers-text:#535353;
    --issued-credential-template-card-dd-text:#535353;
    --issued-credential-template-card-dd-bg:#F3F3F3;
    --dropdown-selected-text-color-bg:#667085;
    --dropdown-options-text-color-bg:#344054;

    --issued-credential-template-card-status-done-bg:#3CB90F;
    --issued-credential-template-card-status-under-review-bg:#DF8821;
    --issued-credential-template-card-status-expired-bg: #b9b9b9;
    --issued-credential-template-card-status-partially-issued-bg: #FFA500;
    --issued-credential-template-card-status-revoked-bg:#DF2121;
    --issued-credential-template-card-status-text: #EBF8E7;
    --issued-credential-template-card-sub-container2-border-right: #D8D8D8;
    --issued-credential-template-card-description-text: #6C788F;
    
    
    --min-column-width: 19.125rem;
    --default-font-weight: 0.875rem;
    --max-column-width: 1fr;
    
    --cf-tab-button-padding-y: 0.625rem;
    --cf-tab-button-padding-x: 0.875rem;
    --cf-issued-credential-border-radius: 0.375rem;
    --cf-issued-credential-template-card-padding-y: 1.125rem;
    --cf-issued-credential-template-card-dd-text: 1.063rem;
    --cf-issued-credential-template-card-status-text: 1.063rem;

    --cf-border-width-1: 1px;
    --cf-border-width-2: 1.5px;
   }