@import "~formiojs/dist/formio.full.min.css";
@import "https://stackpath.bootstrapcdn.com/bootstrap/4.4.1/css/bootstrap.min.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: "Inter";
  src: url("./resources/fonts/Inter-VariableFont_slnt,wght.ttf")
    format("truetype");
}
body {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@keyframes toast-enter {
  from {
    transform: translateY(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes toast-leave {
  from {
    transform: translateY(0);
    opacity: 1;
  }
  to {
    transform: translateY(100%);
    opacity: 0;
  }
}

@keyframes toast-timer {
  from {
    width: 100%;
  }
  to {
    width: 0;
  }
}

.animate-enter {
  animation: toast-enter 0.2s ease-out forwards;
}

.animate-leave {
  animation: toast-leave 0.2s ease-in forwards;
}

#root {
  background-color: #ffffff6d;
  font-family: "Inter", sans-serif;
  color: var(--global-text-color);
  min-height: 100vh;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.page-container {
  margin-top: 10px;
}

.container-content {
  margin: 1%;
}

.cw_btn > div {
  color: #c41e1e;
}
.cw_btn > button {
  background-color: #22c55e;
  display: initial;
  float: right;
}
.cw_btn_2 > button {
  background-color: var(--button-color-primary);
  border-color: var(--button-color-primary);
  width: 100%;
  margin-bottom: 0.75rem;
  font-weight: var(--default-font-weight);
  display: initial;
  float: right;
}
.cw_btn_2 > button:hover {
  background-color: var(--button-color-primary-hover);
}
.ci_input div input {
  border: var(--cf-border-width-1) solid var(--formio-border-color);
  border-radius: 0.375rem;
  padding: 1.5rem 1rem;
}
.ci_input .input-group .input {
  border: var(--cf-border-width-1) solid var(--formio-border-color);
  border-radius: 0.375rem 0 0 0.375rem;
  padding: 1.5rem 1rem;
}
.ci_file_input > ul > li {
  border: var(--cf-border-width-1) solid var(--formio-border-color);
  border-radius: 0.375rem;
}
.ci_file_input > .fileSelector {
  border: var(--cf-border-width-1) dashed var(--formio-border-color);
  border-radius: 0.375rem;
  margin-top: 0.5rem;
}
/* .cw_btn_cfPrimaryLite > div{
 color: #c41e1e;
}
.cw_btn_cfPrimaryLite > button{
  background-color: #0585e2;
  border: none;
  display: initial;
  float: right;
}
.cw_btn_cfPrimaryLite > button:hover{
  background-color: #3E74A3;
  display: initial;
  float: right;
} */
/*
! override formio css for required input fields
? Default Style:
* .field-required:after {
*     position: relative;
*     z-index: 10;
* }
*
*.formio-component-label-hidden {
* position: static !important;
* }
*.formio-form {
* position: relative;
* min-height: 80px;
}
*/
.formio-form,
.formio-component-label-hidden,
.field-required::after {
  position: static !important;
}
.alert-success {
  display: none;
}
#formio > .formio-component > .formio-form > .form-group > div > input,
.formio-component > div > .form-group > div > input {
  border: var(--cf-border-width-1) solid var(--formio-border-color);
  border-radius: 0.375rem;
  padding: 1.5rem;
}
#formio > .formio-component > div > .form-group > div > .input-group > .input {
  border: var(--cf-border-width-1) solid var(--formio-border-color);
  border-radius: 0.375rem 0 0 0.375rem;
  padding: 1.5rem;
}

#formio .formio-component ul.list-group li.list-group-item .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}
#formio .formio-component ul.list-group li.list-group-item .col-md-9 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  flex: 1;
}
#formio .formio-component ul.list-group li.list-group-item .col-md-2 {
  min-width: 110px;
  text-align: right;
  white-space: nowrap;
  flex-shrink: 0;
}
#formio .formio-component ul.list-group li.list-group-item .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
}

.custom-recaptcha-width {
  transform: scaleX(1.12);
  transform: scaleY(1);
  transform-origin: center;
  display: flex;
  justify-content: center;
}

.custom-recaptcha-width div {
  width: auto !important;
}

.accordion-content {
  overflow: hidden;
  max-height: 0;
  transition: max-height 0.2s ease-in-out;
}

.accordion-content.open {
  max-height: 200px;
}

/* Screens smaller than 1280px */
@media (max-width: 1280px) {
  /* Stack the 'File Name' and 'Size' vertically */
  #formio .formio-component ul.list-group li.list-group-item .row {
    display: block;
    white-space: normal;
  }
  /* File name stays full width */
  #formio .formio-component ul.list-group li.list-group-item .col-md-9 {
    margin-bottom: 0.5rem;
    white-space: normal;
  }
  /* File size below the file name */
  #formio .formio-component ul.list-group li.list-group-item .col-md-2 {
    text-align: left;
  }
  /* Strong tags for headers like 'File Name' and 'Size' */
  #formio .formio-component ul.list-group li.list-group-header .col-md-9,
  #formio .formio-component ul.list-group li.list-group-header .col-md-2 {
    display: block;
    text-align: left;
  }
}

.input-group-text {
  border: var(--cf-border-width-1) solid var(--formio-border-color);
}
.arm {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.cf-card {
  margin: 2%;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.cf-card-section {
  max-width: 90%;
  margin-left: auto;
  margin-right: auto;
}

.cf-card-head {
  align-items: center;
  display: flex;
}

.cf-card-title-section {
  flex: 1 1 auto;
}

.cf-page-section {
  margin: 2rem;
}

.cf-pb-sig {
  padding-bottom: 25rem;
}

.cf-text-sm {
  font-size: 0.875rem;
  line-height: 1rem;
}

.help-block,
.alert-danger {
  display: none;
}

@keyframes rotate360 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.animate-rotate {
  animation: rotate360 1s linear;
}
