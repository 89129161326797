.__json-pretty {
    line-height: 1.5;
    color: #000000;
    background: #ffffff;
    padding: 10px;
    white-space: pre-wrap;
    word-break: break-word; /* Ensure long text breaks */
    overflow: auto;
  }
  
  .__json-key {
    color: #3538CD;
    font-weight: bold;
  }
  
  .__json-value {
    color: #000000;
  }
  
  .__json-string {
    color: #000000;
  }
  
  .__json-boolean {
    color: #6699cc;
  }
  
  .__json-null {
    color: #bdbdbd;
  }
  
  .__json-pretty-error {
    color: red;
    background: #1e1e1e;
    padding: 10px;
  }
  